import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    viaphone: "via phone",
    viaemail: "via email",
    agency: "Agency",
    compnay: "Company",
    headhunter: "Headhunter",
    others: "Others",
    services: "Services",
    prices: "Prices",
    contact: "Contact",
    privacy: "Privacy",
    imprint: "Imprint",
    accessability: "Accessability",
    thedeveloper: "The developer",
    partner: "Partner",
    team: "Team",
    news: "News",
    references: "References",
    carrier: "Carrier",
    webdevelopment: "Webdevelopment",
    completepackages: "Complete Packages",
    freelancesupport: "Freelance support",
    packages: "Complete Packages",
  },
  de: {
    viaphone: "Telefonisch",
    viaemail: "per E-Mail",
    agency: "Agentur",
    company: "Unternehmen",
    headhunter: "Personaldienstleister",
    others: "Andere",
    services: "Leistungen",
    prices: "Preise",
    contact: "Kontakt",
    privacy: "Datenschutz",
    imprint: "Impressum",
    accessability: "Barrierefreiheit",
    thedeveloper: "Die Webentwicklerin",
    partner: "Partner",
    team: "Team",
    news: "News",
    references: "Referenzen",
    carrier: "Karriere",
    webdevelopment: "Webentwicklung",
    completepackages: "Komplettpakete",
    freelancesupport: "Freiberufliche Unterstützung",
    packages: "Komplettpakete",
  },
  es: {
    viaphone: "Por teléfono",
    viaemail: "por correo electrónico",
    agency: "Agencia",
    company: "Empresa",
    headhunter: "Cazatalentos",
    others: "Otros",
    services: "Servicios",
    prices: "Precios",
    contact: "Contacto",
    privacy: "Privacidad",
    imprint: "Impresión",
    thedeveloper: "La Desarrolladora Web",
    partner: "Socios",
    team: "Equipo",
    news: "Noticias",
    references: "Referencias",
    carrier: "Carrera",
    webdevelopment: "Desarrollo Web",
    completepackages: "Paquetes Completos",
    freelancesupport: "Apoyo a autónomos",
    packages: "Paquetes Completos",
  },
};

const i18n = new VueI18n({
  locale: localStorage.getItem("locale")?.substring(0, 2) ?? "de", // set locale
  fallbackLocale: "de",
  messages, // set locale messages
});

export default i18n;
