import Vue from "vue";
import Vuex from "vuex";
import i18n from "@/i18n";
//import dbService from "@/services/dbService.js";
import apiClient from "@/services/apiClient";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    scrollPosition: 0,
    contactBoxOpen: false,
    cmsUrl: process.env.VUE_APP_API_URL,
    tokenR: process.env.VUE_APP_TOKEN_READ,
    tokenW: process.env.VUE_APP_TOKEN_WRITE,
    companyinfo: null,
    recommendations: [],
    refProjects: [],
    packages: [],
    customers: [],
    teams: [],
    technologies: [],
    pages: [],
    modalData: {
      content: "",
      active: false,
    },
    navigation: [],
    languages: [
      {
        ID: 1,
        title: "Deutsch",
        short: "DE",
        tag: "de-DE",
        image: "",
        active: false,
      },
      {
        ID: 2,
        title: "English",
        short: "EN",
        tag: "en-EN",
        image: "",
        active: false,
      },
      {
        ID: 3,
        title: "Español",
        short: "ES",
        tag: "es-ES",
        image: "",
        active: false,
      },
    ],
  },
  mutations: {
    SET_DATA(state, { data, stateName }) {
      state[stateName] = data.data;
    },
    SET_SCROLL_POSITION(state, position) {
      state.scrollPosition = position;
    },
    SET_CONTACTBOX_STATUS(state, data) {
      state.contactBoxOpen = data;
    },
  },
  actions: {
    setLanguage({ state }, language) {
      if (!language) {
        let storeLang = localStorage.getItem("locale");
        if (storeLang) {
          language = storeLang;
        } else {
          language = "de-DE";
        }
      }
      // Setze language in LocalStorage
      localStorage.setItem("locale", language);

      state.languages.forEach((l) => {
        l.active = l.tag == language;
      });
      // Setze i18n locale
      i18n.locale = language.substring(0, 2);
    },
    async fetchDataFromApi({ commit }, { apiUrl, stateName }) {
      try {
        const response = await apiClient.get(apiUrl);
        commit("SET_DATA", { data: response.data, stateName });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async sendContactToCMS({ state }, data) {
      try {
        const response = await apiClient.post(
          `${state.cmsUrl}/api/contacts`,
          data
        );
        console.log("Daten erfolgreich an Strapi gesendet:", response.data);
      } catch (error) {
        console.error("Fehler beim Senden der Daten an Strapi:", error);
      }
    },
  },
  modules: {},
});
