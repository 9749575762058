<template>
  <div id="app">
    <ModalBubble />
    <Navigation />
    <ContactBox />
    <Header />
    <main>
      <!--<breadCrumbs />-->
      <div class="mt-3 pt-2 mainView">
        <!-- Transition um router-view -->
        <transition name="toggleMain" mode="out-in">
          <router-view />
        </transition>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useWindowScroll } from "@vueuse/core";
import MetaInfo from "./mixins/MetaInfo";
export default {
  name: "App",
  mixins: [MetaInfo],
  components: {
    Navigation: () => import("@/components/Navigation.vue"),
    ModalBubble: () => import("@/components/ModalBubble.vue"),
    Header: () => import("@/template/HeaderPart.vue"),
    Footer: () => import("@/template/FooterPart.vue"),
    ContactBox: () => import("@/components/ContactBox.vue"),
  },
  data() {
    return {
      footerOpen: false,
    };
  },
  watch: {
    scrollTop(newScrollTop) {
      this.updateLeftValue(newScrollTop);
    },
  },
  computed: {
    ...mapState({
      companyinfo: (state) =>
        state.companyinfo ? state.companyinfo.attributes : null,
      invertLogo: (state) =>
        state.companyinfo
          ? state.companyinfo.attributes.invertlogo.data.attributes.url
          : "/uploads/dummy_Image_4abc3f04dd.webp",
      cmsUrl: (state) => state.cmsUrl,
      scrollTop: (state) => state.scrollPosition,
    }),
  },
  methods: {
    updateLeftValue(scrollTop) {
      const startLeft = -29;
      const endLeft = -40;
      const maxScroll = 180;

      const progress = Math.min(scrollTop / maxScroll, 1);
      const currentLeft = startLeft + (endLeft - startLeft) * progress;
      document.documentElement.style.setProperty(
        "--left-value",
        `${currentLeft}vw`
      );
    },
    toggleFooter() {
      this.footerOpen = !this.footerOpen;
    },
    async fetchInitalData() {
      const fetchData = async (apiUrl, stateName) => {
        await this.$store.dispatch("fetchDataFromApi", {
          apiUrl,
          stateName,
        });
      };

      await Promise.all([
        fetchData(
          `${process.env.VUE_APP_API_URL}/api/companyinfo?populate=*`,
          "companyinfo"
        ),
        fetchData(
          `${process.env.VUE_APP_API_URL}/api/recommendations?locale=de&sort=lastname:desc&populate=*`,
          "recommendations"
        ),
        fetchData(
          `${process.env.VUE_APP_API_URL}/api/references?locale=de&sort=launchDate:desc&populate=*`,
          "refProjects"
        ),
        fetchData(
          `${process.env.VUE_APP_API_URL}/api/packages?locale=de&populate=*`,
          "packages"
        ),
        fetchData(
          `${process.env.VUE_APP_API_URL}/api/teams?locale=de&populate=*`,
          "teams"
        ),
        fetchData(
          `${process.env.VUE_APP_API_URL}/api/technogogies?locale=de&populate=*`,
          "technologies"
        ),
        fetchData(
          `${process.env.VUE_APP_API_URL}/api/customers?locale=de&populate=*`,
          "customers"
        ),
        fetchData(
          `${process.env.VUE_APP_API_URL}/api/navigations?populate=*`,
          "navigation"
        ),
        fetchData(
          `${process.env.VUE_APP_API_URL}/api/pages?populate[pageSections][populate]=*&populate=header_image&populate[SEO][populate]=*`,
          "pages"
        ),
      ]);
    },
  },
  created() {
    this.fetchInitalData();
    this.$store.dispatch("setLanguage");
  },
  mounted() {
    const { y } = useWindowScroll();
    const handleScroll = () => {
      this.$store.commit("SET_SCROLL_POSITION", y.value);
    };
    window.addEventListener("scroll", handleScroll);
    this.updateLeftValue(this.scrollTop);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="sass">
@import '@/assets/SASS/main.sass'

.toggleMain-enter-active,
.toggleMain-leave-active
    transition: opacity 2s ease

.toggleMain-enter,
.toggleMain-leave-to /* .toggleMain-leave-active in <2.1.8 */
    opacity: 0
</style>
