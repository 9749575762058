import { mapState } from "vuex";
export default {
  data() {
    return {
      seoAlt: {
        title: "digimedialoop",
        description: "Ihre Webagentur in Herrsching am Ammersee",
        keywords: "digimedialoop, webagentur, herrsching",
        image:
          "https://strapi.digimedialoop.de/uploads/DML_Logo_mint_2024_37426ffd12.svg",
        locale: "de",
      },
    };
  },
  computed: {
    ...mapState(["pages"]),
    page: {
      get() {
        return (
          this.pages.find(
            (page) => page.attributes.pageName === this.$route.name
          )?.attributes || {}
        );
      },
      deep: true,
    },
  },
  metaInfo() {
    const { pageTitle, seoImage, seoKeywords, seoDesicription } =
      this.page.SEO || {};
    let image = "";
    if (seoImage && seoImage.data && seoImage.data.attributes) {
      image = this.cmsUrl + seoImage.data.attributes.url;
    }
    return {
      title: pageTitle || this.seoAlt.title,
      htmlAttrs: {
        lang: localStorage.getItem("locale")?.substring(0, 2) ?? "de",
      },
      meta: [
        {
          name: "description",
          content: seoDesicription || this.seoAlt.description,
        },
        { name: "robots", content: "index, follow" },
        {
          name: "keywords",
          content: seoKeywords || this.seoAlt.keywords,
        },
        {
          property: "og:title",
          content: pageTitle || this.seoAlt.title,
        },
        {
          property: "og:description",
          content: seoDesicription || this.seoAlt.description,
        },
        { property: "og:image", content: image || this.seoAlt.image },
        {
          property: "og:locale",
          content: this.seoAlt.locale,
        },
      ],
    };
  },
};
