import axios from "axios";

const token = process.env.VUE_APP_TOKEN;

const apiClient = axios.create({
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
  timeout: 10000,
});

export default apiClient;
