import Vue from "vue";
import App from "./App.vue";
import { initRouter } from "./router";
import store from "./store";

import i18n from "./i18n";

// VUE FONT AWESOME
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fas, fab);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// VUE BOOTSTRAP
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// VUE Meta
import Meta from "vue-meta";
Vue.use(Meta);

// VUE MOMENT
import moment from "moment";
import VueMoment from "vue-moment";
Vue.use(VueMoment, moment);

// VUE MATOMO
/*import VueMatomo from "vue-matomo";
Vue.use(VueMatomo, {
  host: "https://analytics.digimedialoop.de",
  siteId: 1,
  disableCookies: true,
  //router: router,
  requireConsent: false,
  trackInitialView: true,
  debug: false,
  enableHeartBeatTimer: true,
  preInitActions: [
    ['setDocumentTitle', document.title],
    ['setDomains', ['*.digimedialoop.de']],
    ['setDoNotTrack', true]
  ]
});*/

import "./assets/SASS/main.sass";

Vue.config.productionTip = false;

const initApp = async () => {
  // Lade die Navigation vor der Initialisierung des Routers
  await store.dispatch("fetchDataFromApi", {
    apiUrl: `${process.env.VUE_APP_API_URL}/api/navigations?populate=*`,
    stateName: "navigation",
  });

  const router = await initRouter(); // Warte auf die Initialisierung des Routers

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
};

initApp();
