import Vue from "vue";
import Router from "vue-router";
import store from "@/store"; // Importiere den Store

Vue.use(Router);

const staticRoutes = [
  {
    // Catch-all Route für alle anderen Pfade
    path: "*",
    redirect: "/404", // Umleitung auf die 404-Seite
  },
];

const createDynamicRoutes = () => {
  return store.state.navigation.map((nav) => ({
    path: nav?.attributes?.navLink,
    name: nav?.attributes?.pageName?.data?.attributes?.pageName,
    component: () => import(`@/views/CmsView.vue`),
  }));
};

let router;

const createRouter = () => {
  return new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [...staticRoutes, ...createDynamicRoutes()],
    scrollBehavior(to) {
      // , from, savedPosition
      return new Promise((resolve) => {
        // Verwende setTimeout, um das Scrollen nach der Transition zu verzögern
        setTimeout(() => {
          if (to.hash) {
            // Wenn ein Anchor-Link vorhanden ist, zum Element scrollen
            resolve({
              selector: to.hash,
              behavior: "smooth",
            });
          } else {
            // Standardmäßig zur oberen Position der Seite scrollen
            resolve({ x: 0, y: 0, behavior: "smooth" });
          }
        }, 400); // Verzögerung in Millisekunden (anpassen nach Bedarf)
      });
    },
  });
};

export const initRouter = async () => {
  //await store.dispatch("loadNavigation");
  router = createRouter();
  return router;
};

export default router;